.block {
  display: block; }
.card {
  width: auto;
  max-width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 2%;
  padding: 8px 16px 8px 12px;
  border-radius: 12px;
  background-color: $card;
  .plogo {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    object-fit: scale-down; }
  .name {
    margin: 0;
    padding: 0;
    display: block;
    color: $blue;
    font-size: 14px;
    line-height: 1; }
  .desc {
    display: block;
    margin: 4px 0;
    padding: 0;
    color: $white;
    font-size: 10px;
    line-height: 1; }
  .status {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1px 4px;
    font-size: 10px;
    border-radius: 4px;
    background-color: $card; }
  .team {
    position: absolute;
    right: 104px;
    padding: 0 4px;
    color: $grey;
    font-size: 10px;
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 16px;
      padding: 1px 4px 0;
      border-radius: 8px;
      background-color: $card; } }
  .join {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    color: $white !important;
    font-size: 10px;
    border-radius: 4px;
    background-color: $magenta; } }
