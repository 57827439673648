/* Base */

html {
  font-family: $basic;
  font-weight: normal;
  font-size: 1rem;
  font-display: swap;
  font-weight: normal;
  font-smooth: always;
  -webkit-font-smoothing: antialised;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  margin: 0;
  padding: 0; }
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden; }

img {
  max-width: 100%;
  height: auto; }

.logo {
  &-splash {
    margin: 0 auto;
    padding: 24px 54px; } }

.hidden {
  display: none !important; }

.mw {
  display: flex;
  justify-content: center;
  align-items: center;
  .screen {
    display: block;
    width: 24vw;
    height: 96vh;
    max-width: 375px;
    max-height: 812px;
    margin: 2vh auto;
    border-radius: 40px;
    border: 12px solid #000;
    background: #070031 url('../../assets/png/bg/screen.png') center center no-repeat;
    background-size: cover;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    &.animate {
      transform: translateX(-100%); }
    &.splash,
    &.onboarding {
      background: #070031 url('../../assets/png/bg/splash.png') center center no-repeat;
      background-size: cover; }
    &.not {
      background: #070031 url('../../assets/png/bg/notfound.png') center center no-repeat;
      background-size: cover; }
    @include v-md {
      min-width: 480px;
      max-height: 88vh;
      margin: 6vh auto;
      overflow: hidden; }
    @include v-sm {
      width: 100vw;
      height: 100vh;
      min-width: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      min-height: 100vh;
      overflow: hidden;
      margin: 0;
      border-radius: 0;
      border: none; }
    @include v-xs {
      width: 100vw;
      height: 100vh;
      min-width: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      min-height: 100vh;
      overflow: hidden;
      margin: 0;
      border-radius: 0;
      border: none; } } }

a, * a {
  color: inherit !important;
  text-decoration: none !important; }
