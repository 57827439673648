.title {
  width: 100%;
  margin: 16px auto;
  color: $white !important;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  &.form {
    font-size: 16px;
    color: $blue !important; }
  &.medium {
    padding: 0;
    font-size: 14px; }
  &.mini {
    padding: 0;
    color: $white !important;
    font-size: 13px;
    font-weight: 300; } }

.w100 {
  font-weight: 100; }
.w200 {
  font-weight: 200; }
.w300 {
  font-weight: 300; }
.w400 {
  font-weight: 400; }
.w500 {
  font-weight: 5100; }
.w600 {
  font-weight: 600; }
.w700 {
  font-weight: 700; }
.w800 {
  font-weight: 800; }
.w900 {
  font-weight: 900; }

.fz {
  &-40 {
    font-size: 3.95em;
    @include v-sm {
      font-size: 1.5em; } }
  &-32 {
    font-size: 2.75em;
    @include v-sm {
      font-size: 1.5em; } }
  &-24 {
    font-size: 2.0625em;
    @include v-sm {
      font-size: 1.25em; } }
  &-20 {
    font-size: 1.71875em;
    @include v-sm {
      font-size: 1.15em; } }
  &-18 {
    font-size: 1.5468em;
    @include v-sm {
      font-size: 1.05em; } }
  &-16 {
    font-size: 1.375em;
    @include v-sm {
      font-size: 1em; } }
  &-14 {
    font-size: 1.203em;
    @include v-sm {
      font-size: 1em; } }
  &-13 {
    font-size: 1.117em;
    @include v-sm {
      font-size: .95em; } }
  &-12 {
    font-size: 1.03125em;
    @include v-sm {
      font-size: .85em; } } }
