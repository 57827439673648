// Colors
.c {
  &-white {
    color: $white !important; }
  &-pink {
    color: $pink !important; }
  &-dark {
    color: $dark !important; }
  &-blue {
    color: $blue !important; }
  &-golden {
    color: $golden !important; } }

// Background Colors
.bg {
  &-body {
    background-color: $body !important; }
  &-white {
    background-color: $white !important; }
  &-pink {
    background-color: $pink !important; } }
