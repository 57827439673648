.screen {
  position: relative;
  padding: 72px 0;
  .appbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 32px 0 16px;
    z-index: 100;
    background-color: $appbar;
    .logo {
      width: 104px !important;
      height: 24px !important;
      object-fit: scale-down; }
    .icons {
      display: flex;
      justify-content: flex-end;
      width: 68px;
      transform: translate(4px,4px);
      &.left {
        transform: translate(12px,4px);
        justify-content: flex-start !important;
        button {
          width: 24px;
          height: 24px;
          margin: 0 !important;
          padding: 0 !important;
          background-color: #ffffff00;
          border: none;
          svg {
            width: 16px !important;
            height: 16px !important; } } }
      a {
        width: 24px;
        height: 24px;
        object-fit: scale-down; }
      > a {
        width: 24px;
        margin-right: 16px;
        background-color: #ffffff00; } } }
  .tabbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px 16px;
    background-color: $dark;
    z-index: 101;
    .linkto {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg {
        width: 20px !important;
        height: 20px !important;
        margin: 0 auto; }
      small {
        color: $grey;
        font-size: 9px;
        text-align: center;
        clear: both; } } }
  .content {
    width: 100%;
    height: auto;
    padding: 0 32px; } }
