.form {
  margin: 24px;
  display: flex;
  flex-direction: column;
  textarea {
    width: 100%;
    height: 104px;
    line-height: 1.25;
    margin: 8px 0;
    padding: 16px 24px;
    color: $white;
    font-size: 14px;
    font-weight: 300;
    border-radius: 28px;
    border: none;
    outline: none;
    background-color: $input;
    @include placeholder {
      color: $grey; } }
  input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin: 8px 0;
    padding: 0 24px;
    color: $white;
    font-size: 14px;
    font-weight: 300;
    border-radius: 28px;
    border: none;
    outline: none;
    background-color: $input;
    @include placeholder {
      color: $grey; }
    &[type="submit"] {
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
      background-color: $magenta; } } }
