$pink: #D62C6D;
// $body: #11111D
$body: #312C4F;
$dark: #04070F;
$appbar: #111723;

$grey: rgba(255, 255, 255, 0.75);

$input: rgba(93, 95, 239, 0.25);
$magenta: #D1085B;

$white: #fff;

$blue: #4ED9EC;
$pink: #FD4979;
$golden: #FEB700;
$green: #39AA8F;

$card: rgba(50, 75, 239, 0.25);

// $blue: linear-gradient(0deg, #0078D4 0.31%, #1380DA 16.23%, #3C91E5 53.05%, #559CEC 81.9%, #5EA0EF 99.81%)
// $card: linear-gradient(180deg, rgba(17, 21, 42, 0.5) 0%, rgba(17, 22, 45, 0.5) 100%)
// $rad: radial-gradient(100% 100% at 50% 0%, rgba(54, 31, 92, 0.5) 0%, rgba(11, 53, 83, 0.5) 100%)

$basic: 'Poppins', sans-serif;

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @contentnet.svg; }
  &:-ms-input-placeholder {
    @content; } }
